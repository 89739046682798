import { proxiedFetch } from "@/backend/helpers/fetch";
import { DisplayCaption } from "@/components/player/display/displayInterface";

let track: chrome.cast.media.Track | null = null;
let busy: boolean = false;

export async function prepareCaptionForCast(
  caption: DisplayCaption | null,
  delay: number = 0,
): Promise<void> {
  try {
    if (busy) {
      return;
    }

    if (!caption) {
      track = null;
      return;
    }

    busy = true;

    const { id } = await proxiedFetch(
      "https://subtitles.easify.dk/api/subtitles?token=cb716126-d17c-464d-88df-5bc8ecec1c63",
      {
        method: "POST",
        body: {
          delay_ms: delay * 1000,
          srt_content: caption.srtData,
        },
      },
    );

    track = new chrome.cast.media.Track(1, chrome.cast.media.TrackType.TEXT);
    track.trackContentId = `https://subtitles.easify.dk/api/subtitles/${id}/vtt?token=cb716126-d17c-464d-88df-5bc8ecec1c63`;
    track.trackContentType = "text/vtt";
    track.subtype = chrome.cast.media.TextTrackType.SUBTITLES;
    track.name = caption?.language;
    track.language = caption?.language;
    track.customData = {};
  } catch (e) {
    console.log(e); // eslint-disable-line

    track = null;
  } finally {
    busy = false;
  }
}

export function applyTrackToMediaInfo(mediaInfo: chrome.cast.media.MediaInfo) {
  if (track) {
    mediaInfo.tracks = [track];
  }
}

export function applyTrackToRequest(request: chrome.cast.media.LoadRequest) {
  if (track) {
    request.activeTrackIds = [track.trackId];
  }
}
